import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Upload, DatePicker, Row, Col } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";
import "./EditEmployee.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import openNotification from "../../components/openNotification";

const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 24
    }
};
const EditEmployee = () => {
    const [form] = Form.useForm();
    const { phone } = useParams();
    const [initial, setInitial] = useState({})

    useEffect(() => {
        axios.get("https://rtcdssbsl.com/xapi/userInfo.php/?phone="+phone)
        .then(res=>{
            let data = res.data.data[0]
            data.dob = moment(data.dob)
            data.present_office_join_date = moment(data.present_office_join_date)
            data.joined = moment(data.joined)
            data.prl_date = moment(data.prl_date)
            setInitial(data);
            form.resetFields();
        })
        .catch(err=>{
            console.log(err);
        })
    }, [])
    
    const onFinish = (values) => {
        values.present_office_join_date = moment(values.present_office_join_date).format('DD/MM/YYYY');
        values.joined = moment(values.joined).format('DD/MM/YYYY');
        values.dob = moment(values.dob).format('DD/MM/YYYY');
        values.prl_date = moment(values.prl_date).format('DD/MM/YYYY');
        
        console.log('Received values of form: ', values);
        axios.post("https://rtcdssbsl.com/xapi/update.php", values)
        .then(res=>{
            console.log(res);
            const data = res.data
            openNotification({type: data.status, msg: data.status, desc: data.message})
            window.location = 'https://rtcdssbsl.com/manageEmployee';
        })
        .catch(err=>{
            console.log(err);
        })
    };
    return (
        <div className="formAlignment">
            <h3 className="w-100 text-center"><u>Edit Employee</u></h3>
            <Form form={form} {...formItemLayout} layout="vertical" onFinish={onFinish} initialValues={initial}>
                <Row>
                    <Col span={11} className="">
                        <Form.Item
                            name="name_bn"
                            label="নাম (বাংলা)"
                            required
                            tooltip="This is a required field"
                            rules={[
                                {
                                required: true,
                                message: "Please enter name in bengali.",
                                },
                                {
                                whitespace: true,
                                },
                                {
                                min: 3,
                                message: "Name should be at least 3 character long.",
                                }
                            ]}
                            hasFeedback
                            >
                            <Input placeholder="Name in Bengali" />
                        </Form.Item>
                        <Form.Item
                            name="name_en"
                            label="নাম (ইংরেজী)"
                            required
                            tooltip="This is a required field"
                            rules={[
                                {
                                required: true,
                                message: "Please enter name in English.",
                                },
                                {
                                whitespace: true,
                                },
                                {
                                min: 3,
                                message: "Name should be at least 3 character long.",
                                }
                            ]}
                            hasFeedback
                            >
                            <Input placeholder="Name in English" />
                        </Form.Item>
                        <Form.Item
                            name="designation"
                            label="পদবী"
                            required
                            tooltip="This is a required field"
                            rules={[
                                {
                                required: true,
                                message: "Designation is required.",
                                },
                                { whitespace: true },
                            ]}
                            >
                            <Input placeholder="Designation" />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="কর্মস্থল (বর্তমান)"
                            required
                            tooltip="This is a required field"
                            rules={[
                                {
                                required: true,
                                message: "This field is required.",
                                },
                                { whitespace: true },
                            ]}
                            >
                            <Input placeholder="Address of Present Office" />
                        </Form.Item>
                        <Form.Item label="জন্ম তারিখ" name="dob">
                            <DatePicker
                                style={{ width: "100%" }}
                                picker="date"
                                placeholder="Choose date of birth"
                            />
                        </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                        <Form.Item
                            name="joined"
                            label="চাকুরীতে যোগদানের তারিখ"
                            >
                            <DatePicker
                                style={{ width: "100%" }}
                                picker="date"
                                placeholder="Choose date of join at job"
                            />
                        </Form.Item>
                        <Form.Item
                            name="present_office_join_date"
                            label="বর্তমান পদে যোগদানের তারিখ"
                            >
                            <DatePicker
                                style={{ width: "100%" }}
                                picker="date"
                                placeholder="Choose date"
                            />
                        </Form.Item>
                        <Form.Item
                            name="prl_date"
                            label="পি.আর.এল গমনের তারিখ"
                            >
                            <DatePicker
                                style={{ width: "100%" }}
                                picker="date"
                                placeholder="Choose date"
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="মোবাইল নাম্বার"
                            required
                            tooltip="This is a required field"
                            rules={[
                                {
                                    required: true,
                                    message: "This is a required field."
                                },
                                {
                                    min: 11,
                                    message: "Mobile number should be at least 11 characters long."
                                },
                                {
                                    max: 15,
                                    message: "Mobile number should be at most 15 characters long."
                                }
                            ]}
                            hasFeedback
                            >
                            <Input placeholder="Mobile Number"/>
                        </Form.Item>
                        <Form.Item
                            label="ই-মেইল ঠিকানা"
                            requiredMark="optional"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: "Please enter a valid email."
                                },
                                {
                                    whitespace: true,
                                    message: ''
                                }
                            ]}
                            hasFeedback
                            >
                            <Input placeholder="Email" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Form.Item name="image" label="ছবি" requiredMark="optional" hasFeedback className="w-100">
                    <Form.Item
                        name="dragger"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                        className="w-100"
                        >
                        <Upload.Dragger name="files" action="https://test.rtcdssbsl.com/xapi/uploadImage.php" className="w-100">
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item> */}
                <Form.Item className="text-center">
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditEmployee;
