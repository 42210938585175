import { Alert, Button, Carousel, Col, Row } from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Marquee from 'react-fast-marquee';
import './HomePageContent.css'

const HomePageContent = () => {
    const [update, setUpdate] = useState('')
    useEffect(()=>{
        axios.get('https://rtcdssbsl.com/xapi/getNotice.php').then(data=>{
            setUpdate(data.data.data[0].content)
        }).catch(e=>{
            console.log(e)
        })
    },[])
    return (
        <>
            <Carousel autoplay>
                    <div>
                        <img src="https://rtcdssbsl.com/images/img2.png" className='contentStyle'/>
                    </div>
                    <div>
                        <img src="https://rtcdssbsl.com/images/img1.png" className='contentStyle'/>
                    </div>
                    <div>
                        <img src="https://rtcdssbsl.com/images/img2.png" className='contentStyle'/>
                    </div>
                    <div>
                        <img src="https://rtcdssbsl.com/images/img1.png" className='contentStyle'/>
                    </div>
            </Carousel>
            <Alert
            type="success"
            message={
                <div>
                    <div className="row py-0 my-0">
                        <div className="col-2 text-center latest py-1">
                            Updates
                        </div>
                        <div className="col-10 py-1">
                            <Marquee pauseOnHover gradient={false}>
                                {/* দেশে চলমান করোনা ভাইরাস পরিস্থিতিতে প্রধানমন্ত্রী শেখ হাসিনা কর্তৃক গত  ০২ এপ্রিল, ২০২০ বৃহস্পতিবার দেশের জনগণের জন্য ৩১ দফা নির্দেশনা দিয়েছেন।  */}
                                {update}
                            </Marquee>
                        </div>
                    </div>
                </div>
            }
            />
            <Row className='w-100'>
                <div className="col-12 col-md-8 pt-1">
                    <div className="row">
                        <div className="col-12 w-xx">
                            <Button type="primary" block>
                                Add New Post
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 rightSide">
                    <div className="row w-100 pt-1">
                        <div className="col-12 noticeContainer mb-2">
                            <div className="row">
                                <div className="col-12 text-center notificationHeader">
                                    Notice Board
                                </div>
                                <div className="col-12">
                                    <ul className='listing'>
                                        <li>
                                            <a href="https://google.com">Google</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 noticeContainer">
                            <div className="row">
                                <div className="col-12 text-center notificationHeader">
                                    Important Links
                                </div>
                                <div className="col-12">
                                    <ul className='listing'>
                                        <li>
                                            <a href="http://rtcdss.barisaldiv.gov.bd/">আঞ্চলিক প্রশিক্ষণ কেন্দ্র, সমাজসেবা অধিদফতর, বরিশাল বিভাগ</a>
                                        </li>
                                        <li>
                                            <a href="https://ibas.finance.gov.bd/ibas/Fixation/">অনলাইনে বেতন নির্ধারণী</a>
                                        </li>
                                        <li>
                                            <a href="http://barisaldiv.gov.bd/site/view/info_officers/%E0%A6%A4%E0%A6%A5%E0%A7%8D%E0%A6%AF-%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A6%E0%A6%BE%E0%A6%A8%E0%A6%95%E0%A6%BE%E0%A6%B0%E0%A7%80-%E0%A6%95%E0%A6%B0%E0%A7%8D%E0%A6%AE%E0%A6%95%E0%A6%B0%E0%A7%8D%E0%A6%A4%E0%A6%BE">তথ্য প্রদানকারী কর্মকর্তাগণের তালিকা</a>
                                        </li>
                                        <li>
                                            <a href="http://barisaldiv.gov.bd/site/page/afe76782-17a2-11e7-9461-286ed488c766/%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A6%9A%E0%A6%BF%E0%A6%A4%E0%A7%8D%E0%A6%B0%E0%A7%87-%E0%A6%AC%E0%A6%B0%E0%A6%BF%E0%A6%B6%E0%A6%BE%E0%A6%B2">মানচিত্রে বরিশাল</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
};

export default HomePageContent;