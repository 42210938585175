import { Button, Form, Input, Upload } from 'antd';
import React, {useState} from 'react';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import './Updates.css'
import axios from 'axios';
import openNotification from '../../components/openNotification';

const normFile = (e) => {
    console.log('Upload event:', e);
  
    if (Array.isArray(e)) {
      return e;
    }
  
    return e?.fileList;
};
const formTailLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 8,
      offset: 4,
    },
  };

const Updates = () => {
    const [updateMsg, setUpdateMsg] = useState("")

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    const updateNow = () => {
        axios.post("https://rtcdssbsl.com/xapi/setNotice.php", {msg: updateMsg})
        .then(res=>{
            console.log(res.data);
            const data = res.data;
            openNotification({type: data.status=="success"?"success":"error", msg: data.status, desc: data.message})
        })
        .catch(err=>{
            console.log(err);
        })
    }
    
    return (
        <div className="row">
            <div className="col-12 col-md-6 text-center mb-3 boxer">
                <h3 className='mb-3'><u>Add New Update</u></h3>
                <Input.Group compact>
                    <Input onChange={e=>setUpdateMsg(e.target.value)} style={{ width: 'calc(100% - 150px)' }} placeholder="Write here..." />
                    <Button type="primary" onClick={updateNow}>Add To Update</Button>
                </Input.Group>
            </div>
            <div className="col-12 col-md-6 mb-3 boxer">
                <h3 className='text-center mb-3'><u>Add New Notice</u></h3>
                <Form name="validate_other" onFinish={onFinish}>
                    <Form.Item name="title" label="Notice title">
                        <Input placeholder='Write notice title...'/>
                    </Form.Item>
                    <Form.Item
                        name="upload"
                        label="Upload file"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                    >
                        <Upload name="logo" action="/upload.do" listType="picture">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item {...formTailLayout}>
                        <Button type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {/* <div className="col-12"></div>
            <div className="col-12"></div> */}
        </div>
    );
};

export default Updates;