import { Input, Button, Table, Space, Modal, Form, DatePicker } from 'antd';
import React, {useState, useEffect} from 'react';
import { SearchOutlined, EditFilled, PrinterOutlined, SendOutlined } from '@ant-design/icons';
import PulseLoader from "react-spinners/PulseLoader";
import { Document, Packer, Paragraph, Table as Tbl, TableCell, TableRow, VerticalAlign, WidthType } from "docx";
import { saveAs } from 'file-saver';
import moment from "moment";
import axios from 'axios';
import './TraineeList.css'
import 'jspdf-autotable';
import Cookies from 'js-cookie';
import openNotification from '../../components/openNotification';
const { TextArea } = Input;


const TraineeList = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [records, setRecords] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
	const [visible, setVisible] = useState(false);
	const [logged, setLogged] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [value, setValue] = useState("Write message...");
	const [course, setCourse] = useState({
		phone: '',
		course_name: "",
		startDate: '',
		endDate: ''
	});
	const [form] = Form.useForm();
    const hasSelected = selectedRowKeys.length > 0;

	const columns = [
		{
		  title: 'ক্র.নং',
		  width: 60,
		  dataIndex: 'slNo',
		  key: 'slNo',
		  fixed: 'left',
		},
		{
		  title: 'নাম (বাংলা)',
		  width: 150,
		  dataIndex: 'nameBn',
		  key: 'nameBn',
		  fixed: 'left',
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.nameBn.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'নাম (ইংরেজী)',
		  dataIndex: 'nameEn',
		  key: 'nameEn',
		  width: 150,
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.nameEn.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'পদবী',
		  dataIndex: 'designation',
		  key: 'designation',
		  width: 150,
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.designation.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'কর্মস্থল (বর্তমান)',
		  dataIndex: 'presentAdd',
		  key: 'presentAdd',
		  width: 200,
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.presentAdd.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'জন্ম তারিখ',
		  dataIndex: 'dob',
		  key: 'dob',
		  width: 150,
		},
		{
		  title: 'বর্তমান পদে যোগদানের তারিখ',
		  dataIndex: 'dateToJoinPresent',
		  key: 'dateToJoinPresent',
		  width: 150,
		},
		{
		  title: 'পি.আর.এল গমনের তারিখ',
		  dataIndex: 'prlDate',
		  key: 'prlDate',
		  width: 150,
		},
		{
			title: "প্রশিক্ষণ গ্রহনের তথ্যাবলী",
			children: [
				{
					title: 'প্রশিক্ষন কোর্সের নাম',
					dataIndex: 'trName',
					key: 'trName',
					width: 150,
					filterDropdown: ({
					  setSelectedKeys,
					  selectedKeys,
					  confirm,
					  clearFilters
					}) => {
					  return (
						<div className='d-flex'>
						  <Input
							autoFocus 
							placeholder="Type to search"
							value={selectedKeys[0]}
							onChange={(e) => {
							  setSelectedKeys(e.target.value ? [e.target.value] : []);
							  confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
							  confirm();
							}}
							onBlur={() => {
							  confirm();
							}}
						  ></Input>
						  <Button
							onClick={() => {
							  clearFilters();
							}}
							type="danger"
						  >
							Reset
						  </Button>
						</div>
					  );
					},
					filterIcon: () => {
					  return <SearchOutlined />;
					},
					onFilter: (value, record) => {
					  return record.trName.toLowerCase().includes(value.toLowerCase());
					}
					
				  },
				  {
					  title: 'তারিখ', 
					  dataIndex: 'date', 
					  key: 'date',
					  width: 150
				  },
				  {
					  title: 'মেয়াদ',
					  dataIndex: 'expire',
					  key: 'expire',
					  width: 150,
				  }
			]
		},
		{
			title: 'মোবাইল নাম্বার',
			dataIndex: 'mobile',
			key: 'mobile',
			width: 150,
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			  }) => {
				return (
				  <div className='d-flex'>
					<Input
					  autoFocus 
					  placeholder="Type to search"
					  value={selectedKeys[0]}
					  onChange={(e) => {
						setSelectedKeys(e.target.value ? [e.target.value] : []);
						confirm({ closeDropdown: false });
					  }}
					  onPressEnter={() => {
						confirm();
					  }}
					  onBlur={() => {
						confirm();
					  }}
					></Input>
					<Button
					  onClick={() => {
						clearFilters();
					  }}
					  type="danger"
					>
					  Reset
					</Button>
				  </div>
				);
			  },
			  filterIcon: () => {
				return <SearchOutlined />;
			  },
			  onFilter: (value, record) => {
				return record.mobile.toLowerCase().includes(value.toLowerCase());
			  }
		},
		{
			title: 'ইমেইল ঠিকানা',
			dataIndex: 'email',
			key: 'email',
			width: 200,
		},
		{
		  title: 'Action',
		  key: 'operation',
		  fixed: 'right',
		  width: 80,
		  render: (xyz, row) => <><EditFilled className='text-center' onClick={()=>showModal(row)} style={{ fontSize: '20px', color: '#4B7BE5' }}/></>,
		},
	  ];

    useEffect(() => {
		setLogged(Cookies.get('isLoggedIn'));
        axios.get(`https://rtcdssbsl.com/xapi/userList.php`)
            .then(res=>{
                setLoading(false);
                setData([]);
                if(res.data.status==='success'){
                    res.data.data.forEach((item, idx)=>{
                        var obj = {
                            key: idx,
                            slNo: idx+1,
                            nameBn: item.name_bn,
                            nameEn: item.name_en,
                            designation: item.designation,
                            presentAdd: item.address,
                            dob: item.dob,
                            dateToJoinPresent: item.present_office_join_date,
                            prlDate: item.prl_date,
                            trName: item.course_name,
                            date: item.startDate,
                            expire: item.endDate,
                            mobile: item.phone,
                            email: item.email
                        };
                        setData(prev=>([...prev,obj]));
                    });
                }
            })
    }, []);

    const printPdf = () => {
		let rows = [];
		rows.push(new TableRow({
			tableHeader: true,
			children: [
				new TableCell({
					width: {
						size: 3505,
						type: WidthType.DXA,
					},
					verticalAlign: VerticalAlign.CENTER,
					children: [new Paragraph("#")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Name")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Designation")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Address")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Phone")],
				})
			],
		}));
		records.forEach((val, idx)=>{
			idx += 1;
			rows.push(new TableRow({
				children: [
					new TableCell({
						width: {
							size: 3505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(idx.toString())],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.nameBn)],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.designation)],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.presentAdd)],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.mobile)],
					}),
				]
			}))
		});
		const table = new Tbl({
			columnWidths: [3505, 5505],
			rows: rows
		});
		const doc = new Document({
			sections: [
				{
					children: [table],
				},
			],
		});

		Packer.toBlob(doc).then((blob) => {
			const d = new Date().toISOString();
			let fileName = "user-list-"+d+".docx";
			saveAs(blob, fileName);
		  });
    }

    const sendSms = () => {
        var phoneList = "";
        records.forEach((val,idx)=>{
            phoneList = phoneList + val.mobile + ",";
        })
        console.log(phoneList)
		console.log(value)
		axios.post('https://rtcdssbsl.com/xapi/sendSms.php',{
			numbers: phoneList,
			text: value
		}).then(res=>{
			let r = res.data
			if(r.status === 'success'){
				openNotification({type: "success", msg: "Success", desc: "Message sent successfully.", })
			}else{
				openNotification({type: "error", msg: "Filed", desc: "Message not sent.", })
			}
		}).catch(e=>{
			console.log(e)
		})
    }
	const showModal = (data) => {
		axios.get('https://rtcdssbsl.com/xapi/trainingInfo.php?phone='+data.mobile).then(res=>{
			console.log(res.data)
			setIsModalVisible(true);
			setCourse({...course, phone:  data.mobile});
			if(res.data.status === 'success'){
				setCourse({...course, course_name:  res.data[0].course_name});
				setCourse({...course, startDate:  moment(res.data[0].startDate)});
				setCourse({...course, endDate:  moment(res.data[0].endDate)});
			}
		}).catch(e=>{
			console.log(e)
		})
	  };
	const onFinish = (val) => {
		if(val.startDate) val.startDate = val.startDate._d.toLocaleDateString()
		if(val.endDate) val.endDate = val.endDate._d.toLocaleDateString()
		val.phone = course.phone;
		axios.post('https://rtcdssbsl.com/xapi/updateCourse.php', val).then(res=>{
			if(res.data.status === "success"){
				openNotification({type: "success", msg: "Success", desc: res.data.message })
				setIsModalVisible(false)
				form.resetFields()
			}else{
				openNotification({type: "error", msg: "Failed", desc: res.data.message })
			}
		}).catch(e=>{
			console.log(e)
			setIsModalVisible(false)
		})
		console.log(val)
	}

    return (
        <div>
            {hasSelected && <div style={{ marginBottom: 16 }}>
                <span style={{ marginLeft: 8 }}>
                    {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                </span>
            </div>}

            <div className="row mt-0">
                {
                    loading ? <div className='spinnerCss'><PulseLoader color={"#3498db"} size={25}/><p className='spinnerData'>Please Wait...</p></div> :
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            onChange: (keys, records) => {
                                setSelectedRowKeys(keys);
                                setRecords(records)
                            }
                        }}
						pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['20', '30', '50', '100', '200', data.length.toString() ], position: ['topRight']}}
                        align="center"
                        columns={columns} 
                        dataSource={data} 
                        scroll={{y: 800 }} 
                    />
                }
            </div>

            <div className="row text-center">
                <div class="col">
                    <Button onClick={()=>{printPdf()}} type="primary" icon={<PrinterOutlined />} size={50}>
                        Print List
                    </Button> 
                    <Space>&nbsp;</Space>
					{
						logged?
						<Button onClick={() => setVisible(true)} type="primary" icon={<SendOutlined />} size={50}>
							Send SMS
						</Button>
						:null
					}
                </div>
            </div>
			<Modal
				title="Write your SMS"
				// centered
				style={{ top: 20 }}
				visible={visible}
				onOk={() => sendSms()}
				onCancel={() => setVisible(false)}
				width={1000}
				footer={[
				<Button key="back" onClick={()=>setVisible(false)}>
				Cancel
				</Button>,
				<Button key="submit" type="primary" loading={loading} onClick={() => sendSms()}>
				Send SMS
				</Button>,
			]}
			>
			<TextArea
			value={value}
			placeholder="Controlled autosize"
			autoSize={{ minRows: 4, maxRows: 10 }}
			onChange={(e)=>{
				setValue(e.target.value)
			}}
			/>
		</Modal>
		<Modal title="প্রশিক্ষণ গ্রহনের তথ্যাবলী আপডেট" visible={isModalVisible} onCancel={()=>setIsModalVisible(false)} footer={null}>
			<Form form={form} layout="vertical" onFinish={onFinish} initialValues={course}>
				<Form.Item
                    name="course_name"
                    label="কোর্সের নাম"
                    required
                    tooltip="This is a required field"
                    hasFeedback
                    >
                    <Input placeholder="Course name"/>
                </Form.Item>
				<Form.Item label="তারিখ" name="startDate">
                    <DatePicker
						value={course.startDate}
                        style={{ width: "100%" }}
                        picker="date"
                        placeholder="Choose start date"
                    />
                </Form.Item>
				<Form.Item label="মেয়াদ" name="endDate">
                    <DatePicker
						value={course.endDate}
                        style={{ width: "100%" }}
                        picker="date"
                        placeholder="Choose expire date"
                    />
                </Form.Item>
				<Form.Item className="text-center">
                    <Button type="primary" htmlType="submit">Save Now</Button>
                </Form.Item>
			</Form>
      </Modal>
        </div>
    );
};

export default TraineeList;