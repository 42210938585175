import { notification } from 'antd';
import React from 'react'

const openNotification = ({type, msg, desc}) => {
    notification[type]({
      message: msg,
      description: desc,
      duration: 3
    });
}
export default openNotification