import React, {useState, useEffect} from 'react';
import {Layout, Menu, Modal, Button} from 'antd';
import './HomePage.css';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SwapOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  HistoryOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  LoginOutlined
} from '@ant-design/icons';
import HomePageContent from '../HomePageContent/HomePageContent';
import { Link, Route, Routes } from 'react-router-dom';
import TraineeList from '../TraineeList/TraineeList';
import ManageEmployee from '../ManageEmployee/ManageEmployee';
import AddEmployee from '../AddEmployee/AddEmployee';
import SmsHistory from '../SmsHistory/SmsHistory';
import Profile from '../Profile/Profile';
import Updates from '../Updates/Updates';
import Login from '../Login/Login';
import EditEmployee from '../EditEmployee/EditEmployee';
import Cookies from 'js-cookie'
import axios from 'axios';

const { Header, Sider, Content } = Layout;
const HomePage = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [login, setLogin] = useState(false);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        setLogin(Cookies.get('isLoggedIn'));
    }, [login])
    
    const handleLogout = () => {
        Cookies.remove('isLoggedIn')
        setLogin(false)
    }
    const toggle = () => {
        setCollapsed(pre => !pre);
    };
    const showModal = () => {
        setVisible(pre=> !pre);
    }
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
          setVisible(false);
          setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo">
                <h3 className='text-light'>RTCDSSBSL</h3>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        <Link className='linkText' to="/">Home</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<UnorderedListOutlined />}>
                        <Link className='linkText' to="/traineeList">Trainee List</Link>
                    </Menu.Item>
                    {
                        login ?
                        <>
                        <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
                            <Link className='linkText' to="/manageEmployee">Employee Management</Link>
                        </Menu.Item>
                        <Menu.Item key="4" icon={<SwapOutlined />}>
                            <Link className='linkText' to="/updates">Updates</Link>
                        </Menu.Item>
                        </>
                        : null
                    }
                    <Menu.Item key="5" icon={<HistoryOutlined />}>
                        <Link className='linkText' to="/smsHistory">SMS History</Link>
                    </Menu.Item>
                    {
                        login ?
                        <>
                            <Menu.Item key="6" icon={<UserOutlined />}>
                                <Link className='linkText' to="/profile">User Profile</Link>
                            </Menu.Item>
                            <Menu.Item key="7" icon={<LogoutOutlined />}>
                                <Link onClick={()=>handleLogout()} className='linkText' to="/logout">Logout</Link>
                            </Menu.Item>
                        </>
                        :
                        <Menu.Item key="7" icon={<LoginOutlined />}>
                            <Link className='linkText' to="/" onClick={showModal}>Login</Link>
                        </Menu.Item>
                    }
                </Menu>
            </Sider>
            <Modal
                title="Login"
                width={"400px"}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={false}
            >
                <Login setLogin={setLogin} setVisible={setVisible}/>
            </Modal>
            <Layout className="site-layout">
            <Header className="site-layout-background d-flex" style={{ padding: 0, backgroundColor: '#D3EBCD' }}>
                <div className='row w-100 align-items-center'>
                    <div className="col-2 col-md-1">
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: toggle,
                        })}
                    </div>
                    <div className="col-10 col-md-11 justify-content-center align-items-center" style={{lineHeight: 'normal'}}>
                        <div className="row text-center align-items-center v-align-middle">
                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <img className='pagedLogo' src='./images/logo-bd.png'/>
                            </div>
                            <div className="col-8 justify-content-center align-items-center v-align-middle">
                                <span className='logoText'>আঞ্চলিক প্রশিক্ষণ কেন্দ্র, সমাজসেবা অধিদফতর, বরিশাল বিভাগ</span>
                            </div>
                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <img className='pagedLogo' src='./images/dss-logo.jpg'/>
                            </div>
                        </div>
                    </div>
                </div>
            </Header>
            <Content className="site-layout-background" style={{margin: '15px 8px',padding: 15,minHeight: '100vh'}}>
                <Routes>
                    <Route path='/' element={<HomePageContent/>}/>
                    <Route path='/traineeList' element={<TraineeList/>}/>
                    <Route path='/manageEmployee' element={<ManageEmployee />}/>
                    <Route path='/smsHistory' element={<SmsHistory/>}/>
                    <Route path='/addEmployee' element={<AddEmployee />}/>
                    <Route path='/updates' element={<Updates/>}/>
                    <Route path='/profile' element={<Profile/>}/>
                    <Route path='/editEmployee/:phone' element={<EditEmployee/>}/>
                </Routes>
            </Content>
            </Layout>
        </Layout>
    );
}
export default HomePage;