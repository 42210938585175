import { Badge, Space, Table } from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { PulseLoader } from 'react-spinners';
import './SmsHistory.css'

const columns = [
    {
      title: 'SL No',
      dataIndex: 'slNo',
      key: 'slNo'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'When',
      key: 'when',
      dataIndex: 'when'
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status'
    }
    
  ];

const SmsHistory = () => {
    const [data, setData] = useState([]);
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        axios.get('https://api.greenweb.com.bd/g_api.php?token=49709ada78c5ee42f310046c58d2aa1a&balance&json')
        .then(res=>{
            console.log(res.data)
            setBalance(res.data[0].response)
        })
    })

    useEffect(() => {
        axios.get(`https://rtcdssbsl.com/xapi/smsHistory.php`)
            .then(res=>{
                setLoading(false);
                setData([]);
                if(res.data.status==='success'){
                    res.data.data.forEach((item, idx)=>{
                        var obj = {
                            key: idx,
                            slNo: idx+1,
                            name: item.name_bn,
                            address: item.address,
                            when: item.dateTime,
                            status: item.status
                        };
                        setData(prev=>([...prev,obj]));
                    });
                }
            })
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col text-right">
                    Total Remaining SMS Count: &nbsp;
                    <Space className='smsCountBadge'>
                        {balance}
                    </Space>
                </div>
            </div>
            <div className="row text-center my-2">
                <h3>SMS History</h3>
            </div>
            <div className="row">
            {
                loading ? <div className='spinnerCss'><PulseLoader color={"#3498db"} size={25}/><p className='spinnerData'>Please Wait...</p></div> :
                <Table columns={columns} dataSource={data} />
            }
            </div>
        </div>
    );
};

export default SmsHistory;