import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie'
import './Login.css';
import axios from 'axios';
import openNotification from '../../components/openNotification';

const Login = ({setLogin, setVisible}) => {
    // const onFinish = (values) => {
    //     console.log('Received values of form: ', values);
    // };
    const handleLogin = (values) => {
        axios.post('https://rtcdssbsl.com/xapi/login.php', values).then(res=>{
            if(res.data.status == "success"){
                setLogin(true);
                setVisible(false)
                Cookies.set('isLoggedIn', true)
                openNotification({type: res.data.status, msg: "Success", desc: res.data.message})
                window.location.reload();
            }else{
                openNotification({type: "error", msg: "Error", desc: res.data.message})
            }
        })
    }

    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{
                remember: true,
            }}
            onFinish={handleLogin}
        >
            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Please input your phone number',
                    },
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
                name="pass"
                rules={[
                    {
                        required: true,
                        message: 'Please input your Password!',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                    Forgot password
                </a>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Log in
                </Button>
            </Form.Item>
        </Form>
    );
};
export default Login;