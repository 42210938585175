import "./App.css";
import HomePage from "./pages/homepage/HomePage";
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
	return (
		<HomePage/>
	);
}

export default App;
