import { Divider, Image } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import './Profile.css';

const Profile = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        axios.get('https://www.rtcdssbsl.com/xapi/userInfo.php/?phone=01712961776')
        .then(res=>{
            if(res.data.status === "success"){
                setData(res.data.data[0]);
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }, [])
    
    return (
        <div>
            <div className="row">
                <div className="left">
                <Image
                    width={180}
                    height={200}
                    src={data.image}
                    />
                </div>
                <div className="right">
                    <h3>{data.name_bn}</h3>
                    <h5>{data.designation}</h5>
                </div>
            </div>
            <Divider orientation="left" plain>
                <b>Information</b>
            </Divider>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h6 className="infoHead">নাম (বাংলা)</h6>
                        <span>{data.name_bn}</span>
                        <Divider/>
                        <h6 className="infoHead">কর্মস্থল (বর্তমান)</h6>
                        <span>{data.address}</span>
                        <Divider/>
                        <h6 className="infoHead">জন্ম তারিখ</h6>
                        <span>{data.dob}</span>
                        <Divider/>
                        <h6 className="infoHead">ই-মেইল ঠিকানা</h6>
                        <span>{data.email}</span>
                        <Divider/>
                    </div>
                    <div className="col">
                        <h6 className="infoHead">নাম (ইংরেজী)</h6>
                        <span>{data.name_en}</span>
                        <Divider/>
                        <h6 className="infoHead">বর্তমান পদে যোগদানের তারিখ</h6>
                        <span>{data.present_office_join_date}</span>
                        <Divider/>
                        <h6 className="infoHead">পি.আর.এল গমনের তারিখ</h6>
                        <span>{data.prl_date}</span>
                        <Divider/>
                        <h6 className="infoHead">মোবাইল নাম্বার</h6>
                        <span>{data.phone}</span>
                        <Divider/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;