import { Input, Button, Tooltip, Table, Space, Modal } from 'antd';
import React, {useState, useEffect, useReducer} from 'react';
import { EditFilled, PrinterOutlined, SearchOutlined, DeleteFilled, UserAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Document, Packer, Paragraph, Table as Tbl, TableCell, TableRow, VerticalAlign, WidthType } from "docx";
import PulseLoader from "react-spinners/PulseLoader";
import { saveAs } from 'file-saver';
import axios from 'axios';
import { Link } from 'react-router-dom';
import openNotification from '../../components/openNotification';

const { confirm } = Modal;


const ManageEmployee = () => {
	const [records, setRecords] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
	const [ignored, forceUpdate] = useReducer(x=>x+1,0);
    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        axios.get(`https://rtcdssbsl.com/xapi/userList.php`)
            .then(res=>{
                setLoading(false);
                setData([]);
                if(res.data.status==='success'){
                    res.data.data.forEach((item, idx)=>{
                        var obj = {
                            key: idx,
                            slNo: idx+1,
                            nameBn: item.name_bn,
                            nameEn: item.name_en,
                            designation: item.designation,
                            presentAdd: item.address,
                            dob: item.dob,
                            dateToJoinPresent: item.present_office_join_date,
                            prlDate: item.prl_date,
                            trName: item.tr_name,
                            date: item.start_date,
                            expire: item.end_date,
                            mobile: item.phone,
                            email: item.email
                        };
                        setData(prev=>([...prev,obj]));
                    });
                }
            })
    }, []);

	const printPdf = () => {
		let rows = [];
		rows.push(new TableRow({
			tableHeader: true,
			children: [
				new TableCell({
					width: {
						size: 3505,
						type: WidthType.DXA,
					},
					verticalAlign: VerticalAlign.CENTER,
					children: [new Paragraph("#")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Name")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Designation")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Address")],
				}),
				new TableCell({
					width: {
						size: 5505,
						type: WidthType.DXA,
					},
					children: [new Paragraph("Phone")],
				})
			],
		}));
		records.forEach((val, idx)=>{
			idx += 1;
			rows.push(new TableRow({
				children: [
					new TableCell({
						width: {
							size: 3505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(idx.toString())],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.nameBn)],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.designation)],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.presentAdd)],
					}),
					new TableCell({
						width: {
							size: 5505,
							type: WidthType.DXA,
						},
						children: [new Paragraph(val.mobile)],
					}),
				]
			}))
		});
		const table = new Tbl({
			columnWidths: [3505, 5505],
			rows: rows
		});
		const doc = new Document({
			sections: [
				{
					children: [table],
				},
			],
		});

		Packer.toBlob(doc).then((blob) => {
			const d = new Date().toISOString();
			let fileName = "user-list-"+d+".docx";
			saveAs(blob, fileName);
		  });
    }

	const showConfirm = (row) => {
		confirm({
		  title: 'Do you Want to delete ' + row.nameBn + '?',
		  icon: <ExclamationCircleOutlined />,
		  content: 'Click "OK" to confirm.',
		  onOk() {
			console.log(row);
			axios.post('https://rtcdssbsl.com/xapi/deleteUser.php',{
				phone: row.mobile
			}).then((res)=>{
				console.log(res.data)
					  const data = res.data
				openNotification({type: data.status, msg: data.status, desc: data.message})
				// forceUpdate();
				window.location.reload(true);
			}).catch(e=>{
				console.log(e)
			})
		  }
		});
	}
	const columns = [
		{
		  title: 'ক্র.নং',
		  width: 60,
		  dataIndex: 'slNo',
		  key: 'slNo',
		  fixed: 'left',
		},
		{
		  title: 'নাম (বাংলা)',
		  width: 150,
		  dataIndex: 'nameBn',
		  key: 'nameBn',
		  fixed: 'left',
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.nameBn.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'নাম (ইংরেজী)',
		  dataIndex: 'nameEn',
		  key: 'nameEn',
		  width: 150,
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.nameEn.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'পদবী',
		  dataIndex: 'designation',
		  key: 'designation',
		  width: 150,
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.designation.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'কর্মস্থল (বর্তমান)',
		  dataIndex: 'presentAdd',
		  key: 'presentAdd',
		  width: 200,
		  filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters
		  }) => {
			return (
			  <div className='d-flex'>
				<Input
				  autoFocus 
				  placeholder="Type to search"
				  value={selectedKeys[0]}
				  onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
					confirm({ closeDropdown: false });
				  }}
				  onPressEnter={() => {
					confirm();
				  }}
				  onBlur={() => {
					confirm();
				  }}
				></Input>
				<Button
				  onClick={() => {
					clearFilters();
				  }}
				  type="danger"
				>
				  Reset
				</Button>
			  </div>
			);
		  },
		  filterIcon: () => {
			return <SearchOutlined />;
		  },
		  onFilter: (value, record) => {
			return record.presentAdd.toLowerCase().includes(value.toLowerCase());
		  }
		},
		{
		  title: 'জন্ম তারিখ',
		  dataIndex: 'dob',
		  key: 'dob',
		  width: 150,
		},
		{
		  title: 'বর্তমান পদে যোগদানের তারিখ',
		  dataIndex: 'dateToJoinPresent',
		  key: 'dateToJoinPresent',
		  width: 150,
		},
		{
		  title: 'পি.আর.এল গমনের তারিখ',
		  dataIndex: 'prlDate',
		  key: 'prlDate',
		  width: 150,
		},
		{
			title: 'মোবাইল নাম্বার',
			dataIndex: 'mobile',
			key: 'mobile',
			width: 150,
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			  }) => {
				return (
				  <div className='d-flex'>
					<Input
					  autoFocus 
					  placeholder="Type to search"
					  value={selectedKeys[0]}
					  onChange={(e) => {
						setSelectedKeys(e.target.value ? [e.target.value] : []);
						confirm({ closeDropdown: false });
					  }}
					  onPressEnter={() => {
						confirm();
					  }}
					  onBlur={() => {
						confirm();
					  }}
					></Input>
					<Button
					  onClick={() => {
						clearFilters();
					  }}
					  type="danger"
					>
					  Reset
					</Button>
				  </div>
				);
			  },
			  filterIcon: () => {
				return <SearchOutlined />;
			  },
			  onFilter: (value, record) => {
				return record.mobile.toLowerCase().includes(value.toLowerCase());
			  }
		},
		{
			title: 'ইমেইল ঠিকানা',
			dataIndex: 'email',
			key: 'email',
			width: 200,
		},
		{
			title: 'Action',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (xyz, row) => <>
					<Link to={"/editEmployee/"+row.mobile}><EditFilled  style={{ fontSize: '20px', color: '#4B7BE5' }}/></Link>
					&nbsp;&nbsp;
					<DeleteFilled onClick={() => showConfirm(row)} style={{ fontSize: '20px', color: '#FF6363' }}/>
			</>,
		},
	  ];

    return (
        <div>
            <div className="row mb-2">
                <div className="col">
                    <div style={{ marginBottom: 10 }}>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                        </span>
                    </div>
                </div>
                <div className="col"></div>
                <div className="col">
                    <Tooltip title="Add A New Employee">
                        <Button type="primary" icon={<UserAddOutlined />} block>
                            <Link to="/addEmployee" className='linkText'>Add New Employee</Link>
                        </Button>
                    </Tooltip>
                </div>
            </div>
            
            <div className="row mt-0">
                {
                    loading ? <div className='spinnerCss'><PulseLoader color={"#3498db"} size={25}/><p className='spinnerData'>Please Wait...</p></div> :
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            onChange: (keys, records) => {
                                setSelectedRowKeys(keys);
								setRecords(records)
                            }
                        }}
						pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['20', '30', '50', '100', '200', data.length.toString() ], position: ['topRight']}}
                        align="center"
                        columns={columns} 
                        dataSource={data} 
                        scroll={{ x: 1500, y: 500 }} 
                    />
                }
            </div>

            <div className="row text-center">
                <div class="col">
                    <Button onClick={()=>{printPdf()}} type="primary" icon={<PrinterOutlined />} size={50}>
                        Print User List
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ManageEmployee;